<template>
  <div class="container_con">
    <el-card>
      <div class="con_title"><span></span> 人员详情</div>
      <div class="items">
          <div class="flex_ac item">
              <div class="tt">照片:</div>
              <div class="flex1">
                  <img class="avatar" v-if="info.photo" :src="$http+info.photo" alt="" />
                  <img class="avatar" v-if="!info.photo" src="../../assets/avatar.jpg" alt="" />
              </div>
          </div>
          <div class="flex_ac item">
              <div class="tt">用户名:</div>
              <div class="flex1">{{info.username}}</div>
          </div>
          <div class="flex_ac item">
              <div class="tt">姓名:</div>
              <div class="flex1">{{info.full_name}}</div>
          </div>
          <div class="flex_ac item">
              <div class="tt">手机号:</div>
              <div class="flex1">{{info.mobile}}</div>
          </div>
          <div class="flex_ac item">
              <div class="tt">部门:</div>
              <div class="flex1">{{info.department_name}}</div>
          </div>
          <div class="flex_ac item">
              <div class="tt">职位:</div>
              <div class="flex1">{{info.position_name}}</div>
          </div>
          <div class="flex_ac item">
              <div class="tt">身份证:</div>
              <div class="flex1">{{info.id_card}}</div>
          </div>
          <div class="flex_ac item">
              <div class="tt">住址:</div>
              <div class="flex1">{{info.address}}</div>
          </div>
      </div>
      <div class="bottom_btn" style="justify-content: flex-start;">
          <el-button @click="$router.go(-1)">返回</el-button>
      </div>
    </el-card>
    
  </div>
</template>

<script>
// 上传组件
export default {
  name: "personnelDetail",
  data() {
    return {
      info:''
    };
  },
  created() {
      this.info=window.personnelDetail
  },
  methods: {
      
  },
};
</script>

<style lang="scss" scoped>
.items .item{padding: 10px; line-height:24px; color:#333; font-size: 14px;}
.items .item .tt{color: #888; width:120px}
.avatar{height: 80px; width:80px;  border-radius: 50%; object-fit: cover; }
</style>
